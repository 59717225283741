//get list of all locales
const languages = require('./dynamic-locales/locales.json');

export const globalVariables = {
    name: 'Spark',
    source: 'https://download.onboardapp.co',
    locationCheck: 'https://us-central1-onboard-360-api.cloudfunctions.net/GeoLoc',
    logging: 'https://analytics-prod-kaurg22aqa-uc.a.run.app/track',
    rootLanguage: 'en-CA',
    availableLanguages: languages,
    version: '3.4.7',
    accentColor: '#4a83f1',
};

if (process.env.NODE_ENV == 'development') {
    globalVariables.source = 'http://localhost:8079'; //hack to point to nothing to we can use locale version
    globalVariables.logging = 'https://analytics-dev-kaurg22aqa-uc.a.run.app/track';
}
