import { globalVariables } from '../../global-vars';
import { i18n, loadLocaleMessages, oldLanguageMap, loadPossibleLocales } from '../../i18n';
import { loadConfiguration } from '../../spark/externals';

export const configModule = {
    state: () => ({
        language: globalVariables.rootLanguage,
        _cachedlanguage: false,
        availableLanguages: globalVariables.availableLanguages,
        clientcode: '',
        configuration: {},
        tag: '',
        username: '',
        deviceInfo: false,
        isLowEndDevice: -1,
    }),
    namespaced: true,
    mutations: {
        initialize: (state, s) => {
            for (let j in s) {
                state[j] = s[j];
            }
        },
        setTag(state, tag) {
            state.tag = tag;
        },
        setLanguage(state, language) {
            state.language = language;
        },
        setClient(state, clientcode) {
            state.clientcode = clientcode;
        },
        saveUsername(state, username) {
            state.username = username;
        },
        saveDeviceInfo(state, info) {
            state.deviceInfo = info;
        },
        saveDeviceID(state, id) {
            if (state.deviceInfo) {
                state.deviceInfo.device = id;
            }
        },
        setLowEndDevice(state, boolean) {
            state.isLowEndDevice = boolean;
        },
        cacheLanguage(state) {
            if (state._cachedlanguage != state.language) {
                state._cachedlanguage = state.language;
            }
        },
    },
    getters: {
        getState: (state) => {
            //can filter and exclude certain fields if we don't want to save their state
            return JSON.stringify(state);
        },
        configurationValue: (state) => (key) => {
            return state.configuration ? state.configuration[key] : false;
        },
        language: (state) => {
            return state.language;
        },
        availableLanguages: (state) => {
            return state.availableLanguages.filter((c) => {
                return (state.configuration?.allowed_languages ?? []).includes(c);
            });
        },
        defaultLanguage: (state) => {
            if (
                state.configuration?.default_language &&
                state.availableLanguages[state.configuration.default_language]
            ) {
                return state.configuration.default_language;
            }
        },
        deviceInfo: (state) => {
            return state.deviceInfo;
        },
        devOrg: (state) => {
            return ['dev', 'qa'].indexOf(state.configuration?.id) > -1;
        },
        isLowEndDevice: (state) => {
            return state.isLowEndDevice;
        },
    },
    actions: {
        async setLanguage(context, locale = context.state.language) {
            if (oldLanguageMap[locale]) {
                locale = oldLanguageMap[locale];
            }

            await loadLocaleMessages(locale);
            i18n.global.locale.value = locale;
            document.querySelector('html').setAttribute('lang', locale);

            context.commit('setLanguage', locale);

            const languages = await loadPossibleLocales();
            if (languages?.length) {
                context.state.availableLanguages = languages;
            }
        },
        async loadConfig(context, key = context.state.clientcode) {
            if (!key) {
                return;
            }

            const config = await loadConfiguration(key);
            if (!config || typeof config != 'object') {
                console.warn(`Could not load configuration ${key}`);
                context.state.configuration = {};
                context.commit('setClient', '');
                return;
            }

            config.tag = context.state.tag;
            context.state.configuration = config;

            context.commit('setClient', key);
        },
        async undoLanguage(context) {
            if (!context.state._cachedlanguage) {
                return;
            }

            context.dispatch('setLanguage', context.state._cachedlanguage);
            context.state._cachedlanguage = false;
        },
    },
};
